import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import BASE_URL from "../../../../config";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import CustomLoading from "../../components/custom_spinner";

const ManageQuestions = () => {
    const [expandedRows, setExpandedRows] = useState(null);
    const [questionsList, setQuestionsList] = useState([]);
    const [flag, setFlag] = useState(null);
    const [loader, setLoader] = useState(true);

    const allowExpansion = (rowData) => {
        return rowData.hasMenu;
    };
    const navigate = useNavigate();

    const handleOne = () => {
        navigate("/addlevelonequestion");
    };
    const handleTwo = (rowData) => {
        navigate("/addleveltwoquestion", { state: { rowData } });
    };

    const editQuestionOne = (rowData) => {
        navigate("/levelonequestionedit", { state: { rowData } });
    };

    const editQuestionTwo = (rowData) => {
        navigate("/leveltwoquestionedit", { state: { rowData } });
    };

    const actionBodyTemplate1 = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch checked={rowData.active === true ? true : false} onChange={() => handleSwitchChange1(rowData)} className="mr-4 " />
                    <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: "top" }} onClick={() => editQuestionOne(rowData)} className="p-button-rounded p-button-success p-button-primary" />
                </div>
            </>
        );
    };

    const handleSwitchChange1 = async (rowData) => {
        const newData = {
            id: rowData?._id,
            active: rowData?.active === true ? false : true,
        };
        const res = await axios.patch(`${BASE_URL}/levelOneQuestion/activeInactive`, newData);
        setFlag(res);
    };

    const actionBodyTemplate2 = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch checked={rowData?.active === true ? true : false} onChange={() => handleSwitchChange2(rowData)} className="mr-4 " />
                    <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: "top" }} onClick={() => editQuestionTwo(rowData)} className="p-button-rounded p-button-success p-button-primary" />
                </div>
            </>
        );
    };

    const handleSwitchChange2 = async (rowData) => {
        const newData = {
            id: rowData?._id,
            active: rowData?.active === true ? false : true,
        };
        const res = await axios.patch(`${BASE_URL}/levelTwoQuestion/activeInactive`, newData);
        setFlag(res);
    };
    const expandedTableTemp = (rowData) => {
        const leveltwoquestions = rowData?.leveltwoquestions.sort((a, b) => a.id - b.id);
        return (
            <div>
                <Button
                    label="Add L2 Question"
                    className="Btn__Dark mb-3"
                    onClick={() => {
                        handleTwo(rowData);
                    }}
                />

                <DataTable showGridlines={true} value={leveltwoquestions || []}>
                    <Column header="Sequence Id" field="id" />

                    <Column header="Question level Two" field="text" />
                    <Column header="Status" body={status} />
                    <Column header="Action" body={actionBodyTemplate2} />
                </DataTable>
            </div>
        );
    };

    const getQuestionsList = async () => {
        const res = await axios.get(`${BASE_URL}/levelOneQuestion`);
        setQuestionsList(res?.data?.data);
        setLoader(false);
    };

    const status = (rowData) => {
        const statusColor = rowData?.active ? "#14A38B" : "#FF7171";

        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.active ? "Active" : "InActive"}</b>
            </p>
        );
    };

    useEffect(() => {
        getQuestionsList();
    }, [flag]);
    return (
        <>
            <div className=" card">
                <Button label="Add L1 Question" className="Btn__Dark" onClick={handleOne} />
            </div>
            <div className="card">
                {loader ? (
                    <CustomLoading />
                ) : (
                    <DataTable
                        filter
                        expandedRows={expandedRows}
                        rows={5}
                        paginator
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                        value={questionsList}
                        responsiveLayout="scroll"
                        onRowToggle={(e) => {
                            setExpandedRows(e.data);
                        }}
                        emptyMessage="No record found."
                        rowExpansionTemplate={expandedTableTemp}
                    >
                        <Column expander={allowExpansion} style={{ width: "3em" }} />
                        <Column header="Sequence Id" field="id" />

                        <Column header="Question" field="text" />
                        <Column header="Status" body={status} />
                        <Column header="Assinged To Role" field="role.title" />
                        <Column header="Action" body={actionBodyTemplate1} />
                    </DataTable>
                )}
            </div>
        </>
    );
};

export default ManageQuestions;
