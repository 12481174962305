import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";

const SupportStats = ({ supportStats }) => {
    if (!supportStats || !supportStats || supportStats.length === 0) {
        return;
    }

    return (
        <div className="card">
            <h5>
                <p className="Page__Header">
                    <b>Support Complaints Per Machine</b>
                </p>
            </h5>
            <DataTable value={supportStats}>
                {Object.keys(supportStats[0])?.map((header, index) => (
                    <Column key={index} header={header} field={header} />
                ))}
            </DataTable>
        </div>
    );
};

export default SupportStats;
