import { createAsyncThunk } from "@reduxjs/toolkit";
import BASE_URL from "../../../config";
import axios from "axios";

export const loginAction = createAsyncThunk("v1/adminAuthentication/login", async (body, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${BASE_URL}/adminAuthentication/login`, body);

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});
