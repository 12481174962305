import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import React, { useRef, useState } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import BASE_URL from "../../../../config";

const ResetAdminPassword = () => {
    const [loadingBtn, setLoadingBtn] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { newData } = location.state || {};
    const userEmail = newData?.email;

    const toast = useRef(null);
    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };
    const validationSchema = Yup.object({
        password: Yup.string().required("Password is required"),
        confirmPassword: Yup.string().required("Confirm Password is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        onSubmit: async (values) => {
            setLoadingBtn(true);
            const newData = {
                email: userEmail,
                password: values?.password,
            };
            await axios
                .post(`${BASE_URL}/adminAuthentication/resetPassword`, newData)
                .then((response) => {
                    showToast("success", "Success", response?.data?.message);
                    setLoadingBtn(false);
                    setTimeout(() => {
                        navigate("/");
                    }, 2000);
                })
                .catch((err) => {
                    showToast("error", "Error", err.response.data.message);
                    setLoadingBtn(false);
                });
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <div className="card">
            <div className=" my-3">
                <div>
                    <p className="Page__Header">Reset Password</p>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12 md:col-4">
                        <label htmlFor="password" className="Label__Text">
                            Password
                        </label>
                        <InputText id="password" value={formik.values.password} onChange={formik.handleChange} placeholder="Enter Password" className="Input__Round" />
                        {getFormErrorMessage("password")}
                    </div>
                    <div className="col-12 md:col-4">
                        <label htmlFor="confirmPass" className="Label__Text">
                            Confirm Password
                        </label>
                        <InputText id="confirmPassword" value={formik.values.confirmPassword} onChange={formik.handleChange} placeholder="Re Enter Password" className="Input__Round" />
                        {getFormErrorMessage("confirmPassword")}
                    </div>
                </div>
                <div className="flex justify-content-center mt-3">
                    <Button label="Reset Password" type="submit" className="Btn__Dark" loading={loadingBtn} />
                </div>
            </form>
            <Toast ref={toast} />
        </div>
    );
};

export default ResetAdminPassword;
