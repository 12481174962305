import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import BASE_URL from "../../../../config";
import axios from "axios";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { useRef } from "react";

const AddMachine = () => {
    const [userList, setUserList] = useState([]);
    const [searchBtnDisable, setSearchBtnDisable] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const navigate = useNavigate();
    const toast = useRef(null);

    const typeList = [
        {
            _id: "snack",
            name: "Snacks",
        },
        {
            _id: "coffee",
            name: "Coffee",
        },
        {
            _id: "combo",
            name: "Combo",
        },
        {
            _id: "drink",
            name: "Drink",
        },
        {
            _id: "others",
            name: "Others",
        },
    ];

    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };
    const validationSchema = Yup.object({
        serialNumber: Yup.string().required("This Field is required"),
        machine: Yup.string().required("This Field is required"),
        route: Yup.string().required("This Field is required"),
        location: Yup.string().required("This Field is required"),
        user: Yup.string().required("This Field is required"),
        model: Yup.string().required("This Field is required"),
        type: Yup.string().required("This Field is required"),
        deviceId: Yup.string().required("This Field is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            location: "",
            serialNumber: "",
            user: "",
            route: "",
            machine: "",
            type: "",
            model: "",
            deviceId: "",
        },
        onSubmit: async (values) => {
            setLoadingBtn(true);

            await axios
                .post(`${BASE_URL}/machine`, values)
                .then((response) => {
                    showToast("success", "Success", response?.data?.message);
                    setLoadingBtn(false);

                    formik.resetForm();
                })
                .catch((err) => {
                    showToast("error", "Error", err.response.data.message);
                    setLoadingBtn(false);
                });
        },
    });

    useEffect(() => {
        const hasTruthyValue = Object.values(formik.values).some((value) => Boolean(value));

        setSearchBtnDisable(!hasTruthyValue);
    }, [formik.values]);

    //get all users
    const getUserList = async () => {
        const res = await axios.get(`${BASE_URL}/adminUser?role=support`);
        setUserList(res?.data?.data);
    };

    useEffect(() => {
        getUserList();
    }, []);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <div className="card p-6">
            <div className=" my-3">
                <div>
                    <p className="Page__Header">Add New Machine</p>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12 md:col-4">
                        <label htmlFor="serialNumber" className="Label__Text">
                            Serial Number
                        </label>
                        <InputText id="serialNumber" placeholder="Enter Serial Number" value={formik.values.serialNumber} onChange={formik.handleChange} className="Input__Round" />
                        {getFormErrorMessage("serialNumber")}
                    </div>

                    <div className="col-12 md:col-4">
                        <label htmlFor="model" className="Label__Text">
                            Model
                        </label>
                        <InputText id="model" placeholder="Enter Model" value={formik.values.model} onChange={formik.handleChange} className="Input__Round" />
                        {getFormErrorMessage("model")}
                    </div>
                    <div className="col-12 md:col-4">
                        <label htmlFor="type" className="Label__Text">
                            Type
                        </label>
                        <Dropdown id="type" options={typeList} optionValue="_id" optionLabel="name" value={formik.values.type} onChange={formik.handleChange} placeholder="Select Type" className="Dropdown__Round" />
                        {getFormErrorMessage("type")}
                    </div>
                    <div className="col-12 md:col-4">
                        <label htmlFor="deviceId" className="Label__Text">
                            Device ID
                        </label>
                        <InputText id="deviceId" placeholder="Enter Device ID" value={formik.values.deviceId} onChange={formik.handleChange} className="Input__Round" />
                        {getFormErrorMessage("deviceId")}
                    </div>
                    <div className="col-12 md:col-4">
                        <label htmlFor="route" className="Label__Text">
                            Route
                        </label>
                        <InputText id="route" placeholder="Enter Route" value={formik.values.route} onChange={formik.handleChange} className="Input__Round" />
                        {getFormErrorMessage("route")}
                    </div>
                    <div className="col-12 md:col-4">
                        <label htmlFor="location" className="Label__Text">
                            Location
                        </label>

                        <InputText id="location" placeholder="Enter location" value={formik.values.location} onChange={formik.handleChange} className="Input__Round" />
                        {getFormErrorMessage("location")}
                    </div>

                    <div className="col-12 md:col-4">
                        <label htmlFor="machine" className="Label__Text">
                            Machine
                        </label>

                        <InputText id="machine" placeholder="Enter Machine" value={formik.values.machine} onChange={formik.handleChange} className="Input__Round" />
                        {getFormErrorMessage("machine")}
                    </div>
                    <div className="col-12 md:col-4">
                        <label htmlFor="user" className="Label__Text">
                            Assigned To
                        </label>
                        <Dropdown id="user" options={userList} optionValue="_id" optionLabel={(user) => `${user.firstName} ${user.lastName}`} value={formik.values.user} onChange={formik.handleChange} placeholder="Select User" className="Dropdown__Round" />
                        {getFormErrorMessage("user")}
                    </div>
                </div>

                <div className="flex justify-content-center  mt-4">
                    <Button label="Add" type="submit" className="Btn__Dark" disabled={searchBtnDisable} loading={loadingBtn} />
                    <Button
                        label="Cancel"
                        type="button"
                        className="Btn__Dark"
                        onClick={() => {
                            navigate("/managemachines");
                        }}
                    />
                </div>
            </form>
            <Toast ref={toast} />
        </div>
    );
};

export default AddMachine;
