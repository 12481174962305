import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoleListAction } from "../../../store/role/RoleAction";
import axios from "axios";
import BASE_URL from "../../../../config";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import CustomLoading from "../../components/custom_spinner";

const SendAdminOtp = () => {
    const { roleData } = useSelector((state) => state.roleList);
    const [loader, setLoader] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loadingResendBtn, setLoadingResendBtn] = useState(false);

    const [showEmail, setShowEmail] = useState(false);

    const { loginData } = useSelector((state) => state.login);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useRef(null);

    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };
    const generateOtp = async () => {
        const newData = {
            email: formik?.values?.email,
        };
        setLoadingBtn(true);
        await axios
            .post(`${BASE_URL}/otp/generate`, newData)
            .then((response) => {
                showToast("success", "Success", response?.data?.message);
                setLoadingBtn(false);
                setShowEmail(true);
            })
            .catch((err) => {
                showToast("error", "Error", err.response.data.message);
                setLoadingBtn(false);
            });
    };
    const resendOtp = async () => {
        const newData = {
            email: formik?.values?.email,
        };
        setLoadingResendBtn(true);
        await axios
            .post(`${BASE_URL}/otp/resend`, newData)
            .then((response) => {
                showToast("success", "Success", response?.data?.message);
                setLoadingResendBtn(false);
            })
            .catch((err) => {
                showToast("error", "Error", err.response.data.message);
                setLoadingResendBtn(false);
            });
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            otp: "",
        },
        onSubmit: async (values) => {
            setLoadingBtn(true);
            const newData = {
                email: values?.email,
                otp: values?.otp,
            };
            await axios
                .post(`${BASE_URL}/otp/verify`, newData)
                .then((response) => {
                    showToast("success", "Success", response?.data?.message);
                    setLoadingBtn(false);
                    setTimeout(() => {
                        navigate(`/resetadminpassword`, { state: { newData } });
                    }, 2000);
                })
                .catch((err) => {
                    showToast("error", "Error", err.response.data.message);
                    setLoadingBtn(false);
                });
        },
    });

    useEffect(() => {
        dispatch(fetchRoleListAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //get user by id
    const getUser = async () => {
        const res = await axios.get(`${BASE_URL}/adminUser/profile/${loginData?.adminUser?._id}`);
        const userDetails = res?.data;
        formik.setFieldValue("email", userDetails?.data?.email || "");

        setLoader(false);
    };
    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginData, roleData]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <div className="card">
            <div className=" my-3">
                <div>
                    <p className="Page__Header">User Profile</p>
                </div>
            </div>
            {loader ? (
                <CustomLoading />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    {!showEmail && (
                        <div className="grid">
                            <div className="col-4">
                                <label htmlFor="email" className="Label__Text">
                                    Email
                                </label>
                                <InputText id="email" value={formik.values.email} onChange={formik.handleChange} placeholder="Enter Email" className="Input__Round" disabled />
                                {getFormErrorMessage("email")}
                            </div>
                            <div className="col-4">
                                <Button label="Send OTP" type="button" className="Btn__Dark mt-4" onClick={() => generateOtp()} loading={loadingBtn} />
                            </div>
                        </div>
                    )}
                    {showEmail && (
                        <div>
                            <div className="grid  flex justify-content-center">
                                <div className="col-4 ">
                                    <label htmlFor="otp" className="Label__Text">
                                        OTP
                                    </label>
                                    <InputText id="otp" value={formik.values.otp} onChange={formik.handleChange} placeholder="Enter Otp" className="Input__Round " />
                                    {getFormErrorMessage("otp")}
                                </div>
                            </div>
                            <div className="flex justify-content-center mt-5">
                                <Button label="Resend" type="button" className="Btn__Dark" onClick={() => resendOtp()} loading={loadingResendBtn} />
                                <Button label="Submit" type="submit" className="Btn__Dark" loading={loadingBtn} />
                            </div>
                        </div>
                    )}
                </form>
            )}

            <Toast ref={toast} />
        </div>
    );
};

export default SendAdminOtp;
