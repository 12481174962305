import React, { createContext } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import { SOCKETURL } from "./config";

const SocketContext = createContext();

const SocketProvider = ({ children }) => {
    const state = useSelector((state) => state.login);
    const loginData = state?.loginData;
    console.log(loginData);

    const socket = io(`${SOCKETURL}?id=${loginData?.adminUser?._id}&role=${loginData?.adminUser?.role?._id}`);
    console.log(socket);
    return (
        <SocketContext.Provider
            value={{
                socket,
            }}
        >
            {children}
        </SocketContext.Provider>
    );
};

export { SocketProvider, SocketContext };
