import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";

const ReportsCategorizedByMachine = ({ machineStat }) => {
    const ratingBody = (rowData) => {
        let number = rowData?.avgRating;
        return Math.floor(number * 10) / 10;
    };
    return (
        <div className="card">
            <h5>
                <p className="Page__Header">
                    <b>Incident Reports Categorized By Vending Machine</b>
                </p>
            </h5>
            <DataTable value={machineStat}>
                <Column header="Machine Id" field="serialNumber" />
                <Column header="Machine Name" field="machine" />
                <Column header="User" field="user" />
                <Column header="Location" field="location" />
                <Column header="Total Tickets" field="totalTickets" />
                <Column header="Open Tickets" field="openTickets" />
                <Column header="Close Tickets" field="closedTickets" />
                <Column header="Average Rating" body={ratingBody} />
            </DataTable>
        </div>
    );
};

export default ReportsCategorizedByMachine;
