import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import React, { useRef, useState } from "react";
import axios from "axios";
import BASE_URL from "../../../../config";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const SendOtp = () => {
    const [showOtp, setShowOtp] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loadingResendBtn, setLoadingResendBtn] = useState(false);

    const toast = useRef(null);
    const navigate = useNavigate();

    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };
    const validationSchema = Yup.object({
        email: Yup.string().required("Email is required"),
        otp: Yup.string().required("Last name is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: "",
            otp: "",
        },
        onSubmit: async (values) => {
            setLoadingBtn(true);
            const newData = {
                email: formik?.values?.email,
                otp: formik?.values?.otp,
            };
            await axios
                .post(`${BASE_URL}/otp/verify`, newData)
                .then((response) => {
                    showToast("success", "Success", response?.data?.message);
                    setLoadingBtn(false);
                    setTimeout(() => {
                        navigate(`/resetpassword`, { state: { newData } });
                    }, 2000);
                })
                .catch((err) => {
                    showToast("error", "Error", err.response.data.message);
                    setLoadingBtn(false);
                });
        },
    });
    const generateOtp = async () => {
        const newData = {
            email: formik?.values?.email,
        };
        setLoadingBtn(true);
        await axios
            .post(`${BASE_URL}/otp/generate`, newData)
            .then((response) => {
                showToast("success", "Success", response?.data?.message);
                setLoadingBtn(false);
                setShowOtp(true);
            })
            .catch((err) => {
                showToast("error", "Error", err.response.data.message);
                setLoadingBtn(false);
            });
    };
    const resendOtp = async () => {
        const newData = {
            email: formik?.values?.email,
        };
        setLoadingResendBtn(true);
        await axios
            .post(`${BASE_URL}/otp/resend`, newData)
            .then((response) => {
                showToast("success", "Success", response?.data?.message);
                setLoadingResendBtn(false);
            })
            .catch((err) => {
                showToast("error", "Error", err.response.data.message);
                setLoadingResendBtn(false);
            });
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <div></div>
                <div className="login-header pb-5">
                    <p className="login-title"> {!showOtp ? "Verify Email" : "Enter OTP"}</p>
                </div>
                {!showOtp && (
                    <div className="login-form">
                        <form onSubmit={formik.handleSubmit}>
                            <InputText id="email" value={formik.values.email} onChange={formik.handleChange} placeholder="Enter Email" className="Input__Round" />
                            <Button type="button" className="login-button" label="Send OTP" onClick={() => generateOtp()} loading={loadingBtn} />
                        </form>
                    </div>
                )}

                {showOtp && (
                    <div className="login-form">
                        <form onSubmit={formik.handleSubmit}>
                            <InputText type="text" name="otp" className="p-inputtext-sm mb-4" placeholder="Enter OTP" value={formik.values.otp} onChange={formik.handleChange} />
                            <Button type="submit" className="login-button" label="Submit" loading={loadingBtn} />
                            <Button type="button" className="login-button" label="Resend OTP" onClick={() => resendOtp()} loading={loadingResendBtn} />
                        </form>
                    </div>
                )}
            </div>
            <Toast ref={toast} />
        </div>
    );
};

export default SendOtp;
