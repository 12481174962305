import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import BASE_URL from "../../../../config";
import Axios from "axios";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import axios from "axios";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import StatsByCategory from "./Components/StatsByCategory";
import AverageResponseTimeToCloseTicket from "./Components/AverageResponseTimeToCloseTicket";
import ReportsCategorizedByMachine from "./Components/ReportsCategorizedByMachine";
import StatsByArea from "./Components/StatsByArea";
import SupportStats from "./Components/SupportStats";

const Dashboard = () => {
    const [rolesnusers, setRolesnusers] = useState();
    const [statData, setStatData] = useState(null);
    const { loginData } = useSelector((state) => state.login);

    const formik = useFormik({
        initialValues: {
            startDate: "",
            endDate: "",
            role: "",
        },
    });

    const resetForm = async () => {
        formik.resetForm();
    };
    //get all users and roles
    const getUserAndRoleList = async () => {
        const res = await axios.get(`${BASE_URL}/dashboard/filter`);
        setRolesnusers(res?.data?.data?.usersAndRoles);
    };
    useEffect(() => {
        getUserAndRoleList();
    }, []);

    useEffect(() => {
        getStatData();
    }, [formik?.values?.startDate, formik?.values?.endDate, formik?.values?.role]);

    const getStatData = async () => {
        const queryParams = [];

        if (formik?.values?.startDate) {
            queryParams.push(`startDate=${formik?.values?.startDate}`);
        }
        if (formik?.values?.endDate) {
            queryParams.push(`endDate=${formik?.values?.endDate}`);
        }
        if (loginData?.adminUser?.role?.title === "Admin") {
            if (formik?.values?.role?.role) {
                queryParams.push(`role=${formik?.values?.role?.role}`);
            }
            if (formik?.values?.role?.user) {
                queryParams.push(`user=${formik?.values?.role?.user}`);
            }
        } else if (loginData?.adminUser?.role?.title === "Sales") {
            queryParams.push(`role=${loginData?.adminUser?.role?._id}`);
        } else if (loginData?.adminUser?.role?.title === "Support") {
            queryParams.push(`role=${loginData?.adminUser?.role?._id}`);
            queryParams.push(`user=${loginData?.adminUser?._id}`);
        }

        // Join query parameters with '&'
        const queryString = queryParams.join("&");
        if (formik?.values?.startDate) {
        }
        const res = await Axios.get(`${BASE_URL}/dashboard?${queryString}`);
        setStatData(res?.data?.data);
        //   setLoader(false);
    };

    const header1 = <p className="flex justify-content-center text-3xl font-semibold text-green-500 mt-3 pt-4 ">Open Tickets</p>;
    const header2 = <p className="flex justify-content-center text-3xl font-semibold text-red-500 mt-3 pt-4">Closed Tickets</p>;

    return (
        <div className="card py-5">
            <div className="card ">
                <div className=" grid mb-5">
                    {loginData?.adminUser?.role?.title === "Admin" ? (
                        <div className="col-12 md:col-2">
                            <label htmlFor="firstName" className="Label__Text">
                                User and Role
                            </label>
                            <Dropdown id="role" options={rolesnusers} optionValue="value" optionLabel="optionLabel" value={formik.values.role} onChange={formik.handleChange} placeholder="Select User" className="Dropdown__Round" />
                        </div>
                    ) : (
                        ""
                    )}

                    <div className="col-12 md:col-2">
                        <label htmlFor="firstName" className="Label__Text">
                            From date
                        </label>
                        <InputText type="date" id="startDate" value={formik.values.startDate} onChange={formik.handleChange} placeholder="Enter First Name" className="Input__Round" />
                    </div>
                    <div className="col-12 md:col-2">
                        <label htmlFor="firstName" className="Label__Text">
                            To Date
                        </label>
                        <InputText type="date" id="endDate" value={formik.values.endDate} onChange={formik.handleChange} placeholder="Enter First Name" className="Input__Round" />
                    </div>
                    <div className="flex flex-wrap justify-content-center mt-4 mb-5">
                        <Button label="Reset" type="button" className="Btn__Dark mt-1" onClick={() => resetForm()} />
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap justify-content-center">
                <Link to="/opencomplains">
                    <Card style={{ width: "22rem", margin: "2em" }} header={header1}>
                        <p className="flex justify-content-center text-3xl font-bold">{statData?.statusStat?.totalOpenComplaints || 0}</p>
                    </Card>
                </Link>
                <Link to="/closecomplains">
                    <Card style={{ width: "22rem", margin: "2em" }} header={header2}>
                        <p className="flex justify-content-center text-3xl font-bold">{statData?.statusStat?.totalClosedComplaints || 0}</p>
                    </Card>
                </Link>
            </div>

            <div className="grid">
                <StatsByCategory statsByCategoryData={statData?.statByCategory} />
                <StatsByArea statByRoute={statData?.statByRoute} />
                <AverageResponseTimeToCloseTicket avgTimeToCloseTicketStat={statData?.avgTimeToCloseTicketStat} />
            </div>
            <ReportsCategorizedByMachine machineStat={statData?.machineStat} />
            <SupportStats supportStats={statData?.supportStats} />
        </div>
    );
};

export default Dashboard;
