import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoleListAction } from "../../../store/role/RoleAction";
import axios from "axios";
import BASE_URL from "../../../../config";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import CustomLoading from "../../components/custom_spinner";

const UpdateUserProfile = () => {
    const { roleData } = useSelector((state) => state.roleList);
    const [loader, setLoader] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const { loginData } = useSelector((state) => state.login);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useRef(null);

    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };
    const handleClick = () => {
        navigate("/");
    };
    const handleResetPassword = () => {
        navigate("/sendadminotp");
    };
    const validationSchema = Yup.object({
        firstName: Yup.string().required("This Field is required"),
        lastName: Yup.string().required("This Field is required"),
        email: Yup.string().required("This Field is required"),
        whatsApp: Yup.string().required("This Field is required"),
        role: Yup.string().required("This Field is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            whatsApp: "",
            role: "",
        },
        onSubmit: async (values) => {
            setLoadingBtn(true);
            const newData = {
                userId: loginData?.adminUser?._id,
                firstName: values?.firstName,
                lastName: values?.lastName,
                email: values?.email,
                whatsApp: values?.whatsApp,
                role: values?.role,
            };
            await axios
                .put(`${BASE_URL}/adminUser`, newData)
                .then((response) => {
                    showToast("success", "Success", response?.data?.message);
                    setLoadingBtn(false);
                })
                .catch((err) => {
                    showToast("error", "Error", err.response.data.message);
                    setLoadingBtn(false);
                });
        },
    });

    useEffect(() => {
        dispatch(fetchRoleListAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //get user by id
    const getUser = async () => {
        const res = await axios.get(`${BASE_URL}/adminUser/profile/${loginData?.adminUser?._id}`);
        const userDetails = res?.data;
        formik.setFieldValue("firstName", userDetails?.data?.firstName || "");
        formik.setFieldValue("lastName", userDetails?.data?.lastName || "");
        formik.setFieldValue("email", userDetails?.data?.email || "");
        formik.setFieldValue("whatsApp", userDetails?.data?.whatsApp || "");
        const selectedRole = roleData?.data?.filter((item) => item?.title === userDetails?.data?.role)[0]?._id;
        formik.setFieldValue("role", selectedRole || "");

        setLoader(false);
    };
    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginData, roleData]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <div className="card">
            <div className=" my-3">
                <div>
                    <p className="Page__Header">User Profile</p>
                </div>
            </div>
            {loader ? (
                <CustomLoading />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid">
                        <div className="col-4">
                            <label htmlFor="firstName" className="Label__Text">
                                First Name
                            </label>
                            <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} placeholder="Enter First Name" className="Input__Round" />
                            {getFormErrorMessage("firstName")}
                        </div>
                        <div className="col-4">
                            <label htmlFor="lastName" className="Label__Text">
                                Last Name
                            </label>
                            <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} placeholder="Enter Last Name" className="Input__Round" />
                            {getFormErrorMessage("lastName")}
                        </div>

                        <div className="col-4">
                            <label htmlFor="email" className="Label__Text">
                                Email
                            </label>
                            <InputText id="email" value={formik.values.email} onChange={formik.handleChange} placeholder="Enter Email" className="Input__Round" />
                            {getFormErrorMessage("email")}
                        </div>
                        <div className="col-4">
                            <label htmlFor="whatsApp" className="Label__Text">
                                WhatsApp
                            </label>
                            <InputText id="whatsApp" value={formik.values.whatsApp} onChange={formik.handleChange} placeholder="Enter WhatsApp" className="Input__Round" />
                            {getFormErrorMessage("whatsApp")}
                        </div>
                        <div className="col-4">
                            <label htmlFor="role" className="Label__Text">
                                Role
                            </label>
                            <Dropdown id="role" options={roleData?.data} optionLabel="title" optionValue="_id" value={formik.values.role} onChange={formik.handleChange} placeholder="Select Role" className="Dropdown__Round" disabled />
                            {getFormErrorMessage("role")}
                        </div>
                    </div>
                    <div className="flex justify-content-center mt-5">
                        <Button label="Update" type="submit" className="Btn__Dark" loading={loadingBtn} />
                        <Button label="Reset Password" type="button" className="Btn__Dark" onClick={handleResetPassword} />
                        <Button label="Back" type="button" className="Btn__Dark" onClick={handleClick} />
                    </div>
                </form>
            )}

            <Toast ref={toast} />
        </div>
    );
};

export default UpdateUserProfile;
