import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import BASE_URL from "../../../../config";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { useRef } from "react";
import CustomLoading from "../../components/custom_spinner";

const EditMachine = () => {
    const [userList, setUserList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const [machineData, setMachineData] = useState([]);
    const location = useLocation();
    const { rowData } = location.state || {};
    const navigate = useNavigate();

    const toast = useRef(null);

    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };
    const typeList = [
        {
            _id: "snack",
            name: "Snacks",
        },
        {
            _id: "coffee",
            name: "Coffee",
        },
        {
            _id: "combo",
            name: "Combo",
        },
        {
            _id: "drink",
            name: "Drink",
        },
        {
            _id: "others",
            name: "Others",
        },
    ];
    const validationSchema = Yup.object({
        serialNumber: Yup.string().required("This Field is required"),
        machine: Yup.string().required("This Field is required"),
        route: Yup.string().required("This Field is required"),
        location: Yup.string().required("This Field is required"),
        user: Yup.string().required("This Field is required"),
        model: Yup.string().required("This Field is required"),
        type: Yup.string().required("This Field is required"),
        deviceId: Yup.string().required("This Field is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            location: "",
            serialNumber: "",
            user: "",
            route: "",
            machine: "",
            type: "",
            model: "",
            deviceId: "",
        },
        onSubmit: async (values) => {
            setLoadingBtn(true);

            const newData = {
                id: rowData?._id,
                ...values,
            };
            delete newData?.serialNumber;
            await axios
                .put(`${BASE_URL}/machine`, newData)
                .then((response) => {
                    showToast("success", "Success", response?.data?.message);
                    setLoadingBtn(false);
                    setTimeout(() => {
                        navigate("/managemachines");
                    }, 1500);
                })
                .catch((err) => {
                    showToast("error", "Error", err.response.data.message);
                    setLoadingBtn(false);
                });
        },
    });

    //get all users
    const getUserList = async () => {
        const res = await axios.get(`${BASE_URL}/adminUser?role=support`);
        setUserList(res?.data?.data);
    };

    useEffect(() => {
        getUserList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //get machine by id
    const getMachine = async () => {
        const res = await axios.get(`${BASE_URL}/machine/${rowData?._id}`);
        setMachineData(res?.data);
        setLoader(false);
    };
    useEffect(() => {
        getMachine();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowData]);

    //setting initial values of formik
    useEffect(() => {
        formik.setFieldValue("serialNumber", machineData?.data?.serialNumber || "");
        formik.setFieldValue("model", machineData?.data?.model || "");
        formik.setFieldValue("type", machineData?.data?.type || "");
        formik.setFieldValue("deviceId", machineData?.data?.deviceId || "");
        formik.setFieldValue("machine", machineData?.data?.machine || "");
        formik.setFieldValue("route", machineData?.data?.route || "");
        formik.setFieldValue("location", machineData?.data?.location || "");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machineData]);

    useEffect(() => {
        const filteredUser = userList.find((item) => {
            return item?._id === machineData?.data?.user;
        });
        if (filteredUser) formik.setFieldValue("user", filteredUser._id);
    }, [machineData, userList]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <div className="card">
            <div className=" my-3">
                <div>
                    <p className="Page__Header">Edit User</p>
                </div>
            </div>
            {loader ? (
                <CustomLoading />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid">
                        <div className="col-4">
                            <label htmlFor="serialNumber" className="Label__Text">
                                Serial Number
                            </label>
                            <InputText id="serialNumber" placeholder="Enter Serial Number" value={formik.values.serialNumber} onChange={formik.handleChange} disabled className="Input__Round" />
                            {getFormErrorMessage("serialNumber")}
                        </div>
                        <div className="col-4">
                            <label htmlFor="model" className="Label__Text">
                                Model
                            </label>
                            <InputText id="model" placeholder="Enter Model" value={formik.values.model} onChange={formik.handleChange} className="Input__Round" />
                            {getFormErrorMessage("model")}
                        </div>
                        <div className="col-4">
                            <label htmlFor="type" className="Label__Text">
                                Type
                            </label>
                            <Dropdown id="type" options={typeList} optionValue="_id" optionLabel="name" value={formik.values.type} onChange={formik.handleChange} placeholder="Select Type" className="Dropdown__Round" />
                            {getFormErrorMessage("type")}
                        </div>
                        <div className="col-4">
                            <label htmlFor="deviceId" className="Label__Text">
                                Device ID
                            </label>
                            <InputText id="deviceId" placeholder="Enter Device ID" value={formik.values.deviceId} onChange={formik.handleChange} className="Input__Round" />
                            {getFormErrorMessage("deviceId")}
                        </div>
                        <div className="col-4">
                            <label htmlFor="route" className="Label__Text">
                                Route
                            </label>
                            <InputText id="route" placeholder="Enter Route" value={formik.values.route} onChange={formik.handleChange} className="Input__Round" />
                            {getFormErrorMessage("route")}
                        </div>
                        <div className="col-4">
                            <label htmlFor="location" className="Label__Text">
                                Location
                            </label>

                            <InputText id="location" placeholder="Enter location" value={formik.values.location} onChange={formik.handleChange} className="Input__Round" />
                            {getFormErrorMessage("location")}
                        </div>

                        <div className="col-4">
                            <label htmlFor="machine" className="Label__Text">
                                Machine
                            </label>

                            <InputText id="machine" placeholder="Enter Machine" value={formik.values.machine} onChange={formik.handleChange} className="Input__Round" />
                            {getFormErrorMessage("machine")}
                        </div>
                        <div className="col-4">
                            <label htmlFor="user" className="Label__Text">
                                Assigned To
                            </label>
                            <Dropdown id="user" options={userList} optionValue="_id" optionLabel={(user) => `${user.firstName} ${user.lastName}`} value={formik.values.user} onChange={formik.handleChange} placeholder="Select User" className="Dropdown__Round" />
                            {getFormErrorMessage("user")}
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-content-center mr-5 mt-4">
                        <Button label="Update" type="submit" className="Btn__Dark" loading={loadingBtn} />
                        <Button
                            label="Back"
                            type="button"
                            className="Btn__Dark"
                            onClick={() => {
                                navigate("/managemachines");
                            }}
                        />
                    </div>
                </form>
            )}
            <Toast ref={toast} />
        </div>
    );
};

export default EditMachine;
