import React from "react";

const NotFound = () => {
    return (
        <div className="card flex justify-content-center">
            <p>404 - Not Found</p>
        </div>
    );
};

export default NotFound;
