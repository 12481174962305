import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import React, { useRef, useState } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import BASE_URL from "../../../../config";

const ResetPassword = () => {
    const [loadingBtn, setLoadingBtn] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { newData } = location.state || {};
    const userEmail = newData?.email;

    const toast = useRef(null);
    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };
    const validationSchema = Yup.object({
        password: Yup.string().required("Password is required"),
        confirmPassword: Yup.string().required("Confirm Password is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        onSubmit: async (values) => {
            setLoadingBtn(true);
            const newData = {
                email: userEmail,
                password: values?.password,
            };
            await axios
                .post(`${BASE_URL}/adminAuthentication/resetPassword`, newData)
                .then((response) => {
                    showToast("success", "Success", response?.data?.message);
                    setLoadingBtn(false);
                    setTimeout(() => {
                        navigate("*");
                    }, 2000);
                })
                .catch((err) => {
                    showToast("error", "Error", err.response.data.message);
                    setLoadingBtn(false);
                });
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <div className="login-container">
            <div className="login-card">
                <div className="login-header pb-5">
                    <p className="login-title">Reset Password</p>
                </div>

                <div className="login-form">
                    <form onSubmit={formik.handleSubmit}>
                        <InputText id="password" name="password" value={formik.values.password} onChange={formik.handleChange} placeholder="Enter Password" className="Input__Round mb-4" />
                        {getFormErrorMessage("password")}
                        <InputText id="confirmPassword" name="confirmPassword" value={formik.values.confirmPassword} onChange={formik.handleChange} placeholder="Re Enter Password" className="Input__Round mb-4" />
                        {getFormErrorMessage("confirmPassword")}
                        <Button type="submit" className="login-button" label="Reset Password" loading={loadingBtn} />
                    </form>
                </div>
            </div>
            <Toast ref={toast} />
        </div>
    );
};

export default ResetPassword;
