import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";

const AverageResponseTimeToCloseTicket = ({ avgTimeToCloseTicketStat }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const countArr =
            avgTimeToCloseTicketStat &&
            avgTimeToCloseTicketStat.map((item) => {
                return item?.avgTimeToClose;
            });
        const labelArr =
            avgTimeToCloseTicketStat &&
            avgTimeToCloseTicketStat.map((item) => {
                return item?.user;
            });

        const data = {
            labels: labelArr,
            datasets: [
                {
                    label: "User's Average Response Time In Minutes",
                    data: countArr,
                    fill: false,
                    borderColor: "#42A5F5",
                    tension: 0.4,
                },
            ],
        };

        setChartData(data);
    }, [avgTimeToCloseTicketStat]);
    return (
        <div className="col-12 md:col-12">
            <div className="">
                <div className="card">
                    <h5>
                        <p className="Page__Header">
                            <b>Average Response Time To Close Ticket Per User </b>
                        </p>
                    </h5>
                    <Chart type="line" data={chartData} className="w-full" />
                </div>
            </div>
        </div>
    );
};

export default AverageResponseTimeToCloseTicket;
