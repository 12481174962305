import React, { useState, useContext } from "react";
import { SocketContext } from "../../../../Socket";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BASE_URL from "../../../../config";
import { InputText } from "primereact/inputtext";
import Axios from "axios";
import { useEffect } from "react";
import { FilterMatchMode } from "primereact/api";
import CustomLoading from "../../components/custom_spinner";

const ManageComplains = () => {
    const navigate = useNavigate();
    const [complaintList, setComplaintList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const { socket } = useContext(SocketContext);
    const { loginData } = useSelector((state) => state.login);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdAt: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ""));
    };

    const startChat = (rowData) => {
        return <Button label="Open Chat" className="Btn__Dark" onClick={() => navigate("/chat", { state: { complaintId: rowData._id } })} />;
    };
    useEffect(() => {
        setTimeout(() => {
            socket.on("newComplaint", () => {
                getComplaintList();
            });
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginData]);

    const getComplaintList = async () => {
        const res = await Axios.get(`${BASE_URL}/complaint?userId=${loginData?.adminUser?._id}&roleId=${loginData?.adminUser?.role?._id}`);
        setComplaintList(res?.data?.data);
        setLoader(false);
    };

    useEffect(() => {
        getComplaintList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <div className="card">
                <div className="flex justify-content-between my-3">
                    <p className="Manage__Header ">All Complains</p>
                    <InputText className="Input__Round w-15rem" placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
                </div>
                {loader ? (
                    <CustomLoading />
                ) : (
                    <DataTable
                        value={complaintList}
                        emptyMessage="No List found."
                        responsiveLayout="scroll"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                        filters={filters}
                        globalFilterFields={["role.title", "createdAt"]}
                    >
                        <Column header="Machine ID" field="machine.uuid" />
                        <Column header="Location" field="machine.location" />
                        <Column
                            header="User"
                            body={(rowData) => {
                                return rowData?.user?.firstName + " " + rowData?.user?.lastName;
                            }}
                        />
                        <Column header="Category" field="role.title" />
                        <Column header="Date" field="createdAt" />
                        <Column header="Chat" body={startChat} />
                    </DataTable>
                )}
            </div>
        </div>
    );
};

export default ManageComplains;
