import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BASE_URL from "../../../config";

export const fetchRoleListAction = createAsyncThunk("roles/fetch", async (arg, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${BASE_URL}/roles`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});
