import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import BASE_URL from "../../../../config";
import { FilterMatchMode } from "primereact/api";
import CustomLoading from "../../components/custom_spinner";
import { Toast } from "primereact/toast";

const ManageUsers = () => {
    const [userList, setUserList] = useState([]);
    const [flag, setFlag] = useState(null);
    const [loader, setLoader] = useState(true);

    const toast = useRef(null);

    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };

    //Datatables filters criteria
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ""));
    };

    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/adduser");
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions flex ">
                    <InputSwitch checked={rowData.isActive === true ? true : false} className="mr-4 mt-1 w-2.5rem " onChange={() => handleSwitchChange(rowData)} />
                    <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: "top" }} onClick={() => editUserData(rowData)} className="p-button-rounded p-button-success h-2.5rem w-2.5rem	" />
                    <Button tooltip="Delete" icon="pi pi-trash" tooltipOptions={{ position: "top" }} onClick={() => deleteUserData(rowData)} className="p-button-rounded p-button-danger h-2.5rem w-2.5rem	" />
                </div>
            </>
        );
    };

    const handleSwitchChange = async (rowData) => {
        const newData = {
            userId: rowData?._id,
            isActive: rowData?.isActive === true ? false : true,
        };
        const res = await axios.patch(`${BASE_URL}/adminUser/updateStatus`, newData);
        setFlag(res);
    };

    const editUserData = (rowData) => {
        navigate("/edituser", { state: { rowData } });
    };

    const deleteUserData = async (rowData) => {
        const res = await axios
            .delete(`${BASE_URL}/adminUser/${rowData?._id}`)
            .then((response) => {
                showToast("success", "Success", response?.data?.message);
                setFlag(response);
            })
            .catch((err) => {
                showToast("error", "Error", err.response.data.message);
            });
    };

    //get all users
    const getUserList = async () => {
        const res = await axios.get(`${BASE_URL}/adminUser`);
        setUserList(res?.data?.data);
        setLoader(false);
    };

    useEffect(() => {
        getUserList();
    }, [flag]);

    const role = (rowData) => {
        return rowData.role.title;
    };

    const status = (rowData) => {
        const statusColor = rowData?.isActive === true ? "#14A38B" : "#FF7171";

        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.isActive === true ? "Active" : "Inactive"}</b>
            </p>
        );
    };
    return (
        <div>
            <div className=" card ">
                <Button label="Add User" className="Btn__Dark" onClick={handleClick} />
            </div>
            <div className="card">
                <div className="flex justify-content-between my-3">
                    <p className="Manage__Header mt-3">Existing Users</p>
                    <InputText className="Input__Round w-15rem" placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
                </div>
                {loader ? (
                    <CustomLoading />
                ) : (
                    <DataTable
                        value={userList}
                        emptyMessage="No List found."
                        responsiveLayout="scroll"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                        filters={filters}
                        globalFilterFields={["firstName", "lastName", "email", "whatsApp"]}
                    >
                        <Column header="First Name" field="firstName" />
                        <Column header="Last Name" field="lastName" />
                        <Column header="Email" field="email" />
                        <Column header="WhatsApp" field="whatsApp" />
                        <Column header="Role" body={role} />
                        <Column header="Status" body={status} />
                        <Column header="Action" body={actionBodyTemplate} className="flex justify-content-center" />
                    </DataTable>
                )}
            </div>
            <Toast ref={toast} />
        </div>
    );
};

export default ManageUsers;
