import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import BASE_URL from "../../../../config";
import { FilterMatchMode } from "primereact/api";
import { Dialog } from "primereact/dialog";
import QRCode from "react-qr-code";
import CustomLoading from "../../components/custom_spinner";
import { Toast } from "primereact/toast";

const ManageMachines = () => {
    const [machineList, setMachineList] = useState([]);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [rowId, setRowId] = useState(null);
    const [loader, setLoader] = useState(true);
    const [flag, setFlag] = useState(null);

    const toast = useRef(null);

    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };

    // const qrData = `https://wa.me/+50768823964?text=%u00A1Hola!%20Bienvenido%20al%20chatbot%20de%20Coffee%20%26%20Vending,%20por%20favor%20haz%20click%20en%20enviar%20para%20comenzar%20${rowId?.uuid}`;
    const qrText = "¡Hola! Bienvenido al chatbot de Coffee & Vending, por favor haz click en enviar para comenzar " + rowId?.uuid;
    const encodedText = encodeURIComponent(qrText);
    const qrData = `https://wa.me/+50768823964?text=${encodedText}`;

    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/addmachine");
    };
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        location: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ""));
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: "top" }} onClick={() => editMachine(rowData)} className="p-button-rounded p-button-success p-button-primary" />
                    <Button tooltip="Delete" icon="pi pi-trash" tooltipOptions={{ position: "top" }} onClick={() => deleteMachine(rowData)} className="p-button-rounded p-button-danger p-button-primary" />
                </div>
            </>
        );
    };

    const editMachine = (rowData) => {
        navigate("/editmachine", { state: { rowData } });
    };
    const deleteMachine = async (rowData) => {
        const res = await axios
            .delete(`${BASE_URL}/machine/${rowData?._id}`)
            .then((response) => {
                showToast("success", "Success", response?.data?.message);
                setFlag(response);
            })
            .catch((err) => {
                showToast("error", "Error", err.response.data.message);
            });
    };

    const generateQR = (rowData) => {
        return (
            <Button
                label="Generate QR"
                className="Btn__Dark"
                onClick={() => {
                    setRowId(rowData);
                    setDisplayBasic(true);
                }}
            />
        );
    };

    const renderFooter = () => {
        return (
            <div className="flex justify-content-center my-3">
                <Button label="Print QR" className="Btn__Dark w-9rem" onClick={printQR} />
            </div>
        );
    };

    const assignedTo = (rowData) => {
        return `${rowData.userFirstName} ${rowData.userLastName}`;
    };

    //get all machines
    const getMachineList = async () => {
        const res = await axios.get(`${BASE_URL}/machine`);
        setMachineList(res?.data?.data);
        setLoader(false);
    };

    const printQR = () => {
        const printWindow = window.open("", "_blank");
        const contentToPrint = document.getElementById("qr").innerHTML;

        printWindow.document.write(`
            <html>
            <head>
                <title>Print QR Code</title>
                <style>
                    /* Add your print styles here if needed */
                </style>
            </head>
            <div style=" display: flex; justify-content: center; align-items: center; height: 80vh; flex-direction: column;">
            <div style=" height: 500px; width: 500px; display: flex; justify-content: center; align-items: center;">
               ${contentToPrint}
            </div>
            <div style="text-align: center;">
                <p style="width: 500px;">${rowId.uuid}</p>
            </div>
        </div>

            </body>
            </html>
        `);

        printWindow.document.close();
        printWindow.print();
    };
    // const downloadQR = () => {};
    useEffect(() => {
        getMachineList();
    }, [flag]);

    return (
        <div>
            <div>
                <Dialog
                    header="QR Code"
                    visible={displayBasic}
                    style={{ width: "40vw" }}
                    footer={renderFooter()}
                    onHide={() => {
                        setDisplayBasic(false);
                    }}
                >
                    <div id="qr" className="flex justify-content-center mt-3">
                        <QRCode value={qrData} size={450} fgColor="#000000" rbgColor="#ffffff" level="L" />
                    </div>
                </Dialog>
            </div>
            <div className="card">
                <Button label="Add Machine" className="Btn__Dark" onClick={handleClick} />
            </div>
            <div className="card">
                <div className="flex justify-content-between my-3">
                    <p className="Manage__Header mt-3">Existing Machines</p>
                    <InputText className="Input__Round w-15rem" placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
                </div>
                {loader ? (
                    <CustomLoading />
                ) : (
                    <DataTable
                        value={machineList}
                        emptyMessage="No List found."
                        responsiveLayout="scroll"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                        filters={filters}
                        globalFilterFields={["location", "city", "area"]}
                    >
                        {/* <Column header="Make" field="make" /> */}
                        <Column header="Model" field="model" />
                        <Column header="Type" field="type" />
                        <Column header="Location" field="location" />
                        <Column header="Route" field="route" />
                        <Column header="Machine" field="machine" />
                        <Column header="Device ID" field="deviceId" />
                        <Column header="Assigned To" body={assignedTo} />
                        <Column header="Generate QR" body={generateQR} />
                        <Column header="Action" body={actionBodyTemplate} className="flex justify-content-center" />
                    </DataTable>
                )}
            </div>
            <Toast ref={toast} />
        </div>
    );
};

export default ManageMachines;
