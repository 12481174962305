import React, { useState, useRef, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { useLocation } from "react-router-dom";
import BASE_URL, { FILEURL } from "../../../../config";
import axios from "axios";
import { SocketContext } from "../../../../Socket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Image } from "primereact/image";

const ChatBox = () => {
    const { socket } = useContext(SocketContext);
    const location = useLocation();
    const { complaintId } = location.state;
    const [messages, setMessages] = useState([]);
    const [complaint, setComplaint] = useState({ chat: [] });
    const [roleList, setRoleList] = useState(null);
    const { loginData } = useSelector((state) => state.login);
    const [newMessage, setNewMessage] = useState("");
    const chatMessagesRef = useRef(null);
    const formik = useFormik({
        initialValues: {
            roles: complaint?.role?._id,
        },
    });

    const handleSendMessage = () => {
        if (newMessage.trim() !== "") {
            socket.emit("newMessage", { text: newMessage, to: complaint.whatsAppNumber }, (data) => {
                setMessages((preval) => {
                    return [...preval, data.data.chat];
                });
            });
            setNewMessage("");
        }
    };

    const handleSendMessageWithEnter = (event) => {
        if (event.key !== "Enter") {
            return;
        }
        if (newMessage.trim() !== "") {
            socket.emit("newMessage", { text: newMessage, to: complaint.whatsAppNumber }, (data) => {
                setMessages((preval) => {
                    return [...preval, data.data.chat];
                });
            });
            setNewMessage("");
        }
    };
    useEffect(() => {
        setTimeout(() => {
            socket.on("newMessage", (data) => {
                if (complaintId === data.data._id && data.data.chat.from !== "panel") {
                    setMessages((preval) => {
                        return [...preval, data.data.chat];
                    });
                }
            });
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (chatMessagesRef.current) {
            chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
        }
    }, [messages]);
    const getRoleList = async () => {
        const res = await axios.get(`${BASE_URL}/roles`);
        setRoleList(res?.data?.data);
    };

    const getComplaint = async () => {
        const res = await axios.get(`${BASE_URL}/complaint/${complaintId}`);
        setComplaint(res?.data?.data);
        formik.setFieldValue("roles", res?.data?.data?.role?._id);
        setMessages(res?.data?.data?.chat);
    };

    useEffect(() => {
        getComplaint();
        getRoleList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const scrollToBottom = () => {
        if (chatMessagesRef.current) {
            chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
        }
    };

    const closeComplain = async () => {
        const newData = {
            user: loginData?.adminUser?._id,
            complaintId: complaintId,
            status: "close",
        };
        await axios.patch(`${BASE_URL}/complaint`, newData);
        getComplaint();
    };

    const changeRole = async () => {
        const newData = {
            user: loginData?.adminUser?._id,
            complaintId: complaintId || "",
            role: formik.values.roles,
        };
        await axios.patch(`${BASE_URL}/complaint/assignRole`, newData);
    };

    useEffect(() => {
        if (chatMessagesRef.current) {
            scrollToBottom();
        }
    }, [complaint]);
    return (
        <div className="container mt-5" height="80vh">
            <div className="row" height="80vh">
                <div className="grid">
                    <div className="col-12 md:col-7 mt-sm-3 mt-md-0" height="80vh">
                        {/* Chat Box */}
                        <div className="card" height="80vh">
                            <div className="card-header">Chat Box</div>
                            <div className="card-body">
                                {/* Display messages */}
                                <div className="mb-3">
                                    <section>
                                        <div className="container py-5">
                                            <div className="row d-flex justify-content-center">
                                                <div className="card-body chat-box" style={{ height: "380px", overflowY: "auto" }} ref={chatMessagesRef}>
                                                    {messages.map((item) => {
                                                        console.log(item);
                                                        if (item.from !== "panel")
                                                            return (
                                                                <div key={item._id} className={`d-flex flex-column mb-4 align-items-${item.from !== "panel" ? "start" : "end"}`}>
                                                                    <div className={`d-flex flex-row mb-1 align-items-center ${item.from !== "panel" ? "justify-content-start" : "justify-content-end"}`}>
                                                                        <div
                                                                            className={`p-3 ${item.from !== "panel" ? "ms-3" : "me-3"} ${item.from !== "panel" ? "bg-receiver" : "bg-light"}`}
                                                                            style={{
                                                                                borderRadius: 15,
                                                                            }}
                                                                        >
                                                                            {item.isImage ? <Image height="250" src={FILEURL + item.text} preview /> : <p className="small mb-0">{item.text}</p>}
                                                                        </div>
                                                                    </div>
                                                                    <small className={`text-muted message-time ${item.from !== "panel" ? "ms-3" : "me-3"}`}>{moment(item.createdAt).fromNow()}</small>
                                                                </div>
                                                            );
                                                        else {
                                                            return (
                                                                <div key={item._id} className={`d-flex flex-column align-items-${item.from !== "panel" ? "start" : "end"}`}>
                                                                    <div className={`d-flex flex-row mb-1 align-items-center ${item.from !== "panel" ? "justify-content-start" : "justify-content-end"}`}>
                                                                        <div
                                                                            className={`p-3 ${item.from !== "panel" ? "ms-3" : "me-3"} ${item.from !== "panel" ? "bg-receiver" : "bg-light"}`}
                                                                            style={{
                                                                                borderRadius: 15,
                                                                            }}
                                                                        >
                                                                            <p className="small mb-0">{item.text}</p>
                                                                        </div>
                                                                    </div>
                                                                    <small className={`text-muted message-time ${item.from !== "panel" ? "ms-3" : "me-3"}`}>{moment(item.createdAt).fromNow()}</small>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                {/* Message input field */}
                                <div className="input-group mb-3">
                                    {complaint?.status === "close" ? (
                                        <></>
                                    ) : (
                                        <>
                                            <input type="text" className="form-control" placeholder="Type a message..." onChange={(e) => setNewMessage(e.target.value)} value={newMessage} onKeyPress={handleSendMessageWithEnter} />
                                            <button className="btn btn-primary" type="button" onClick={handleSendMessage}>
                                                <FontAwesomeIcon icon={faPaperPlane} />
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-5 mt-1">
                        <form>
                            {/* Sidebar with all users */}
                            <div className="card">
                                <div className="card-header">Chat details</div>
                                <div className="card-body" style={{}}>
                                    <div className="col-12">
                                        <div className="col-12">
                                            <p style={{ fontWeight: "bold" }}>
                                                WhatsApp Number:<span> {complaint?.whatsAppNumber}</span>
                                            </p>
                                            <p>
                                                <span className={complaint?.status === "close" ? "red-900" : "green-900"} style={{ fontWeight: "bold" }}>
                                                    Status:
                                                </span>
                                                <span className={complaint?.status === "close" ? "text-danger" : "text-success"}>{complaint?.status === "close" ? " Closed" : " Open"}</span>
                                            </p>
                                            <p style={{ fontWeight: "bold" }}>Level One Question</p>
                                            <p>{complaint?.levelOneQuestion?.text}</p>
                                            <p style={{ fontWeight: "bold" }}>Level Two Question</p>
                                            <p>{complaint?.levelTwoQuestion?.text}</p>
                                            <p style={{ fontWeight: "bold" }}>Machine</p>
                                            <p>{complaint?.machine?.serialNumber}</p>
                                            <p style={{ fontWeight: "bold" }}>Machine Location</p>
                                            <p>{complaint?.machine?.location}</p>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="role" className="Label__Text">
                                                Assign To
                                            </label>
                                            <Dropdown id="roles" options={roleList} optionLabel="title" optionValue="_id" value={formik.values.roles} onChange={formik.handleChange} placeholder="Select Role" className="Dropdown__Round" disabled={complaint?.status === "close" ? true : false} />
                                        </div>
                                    </div>
                                    <div className="col-12 flex flex-row justify-content-center gap-3">
                                        <Button label="Assign" className="h-3rem Btn__Dark" type="button" onClick={() => changeRole()} disabled={complaint?.status === "close" ? true : false} />

                                        <Button label="Close Chat" type="button" onClick={() => closeComplain()} className="w-15rem" severity="danger" disabled={complaint?.status === "close" ? true : false} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className=" my-3">
                <div>
                    <p className="Page__Header">Logs</p>
                </div>
                <div>
                    {complaint?.logs?.map((item, index) => (
                        <React.Fragment key={index}>
                            {/* <p>{index + 1}.</p> */}
                            <p className="my-1">
                                <span>
                                    <b>• </b>
                                </span>
                                {item}
                            </p>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ChatBox;
