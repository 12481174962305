import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoleListAction } from "../../../store/role/RoleAction";
import axios from "axios";
import BASE_URL from "../../../../config";
import { Toast } from "primereact/toast";
import * as Yup from "yup";

const AddUser = () => {
    const { roleData } = useSelector((state) => state.roleList);
    const [searchBtnDisable, setSearchBtnDisable] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useRef(null);

    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };

    const handleClick = () => {
        navigate("/manageusers");
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        email: Yup.string().required("Email is required"),
        password: Yup.string().required("Password is required"),
        confirmPass: Yup.string()
            .required("Confirm password is required")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
        whatsApp: Yup.string().required("WhatsApp number is required"),
        role: Yup.string().required("Role is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPass: "",
            whatsApp: "",
            role: "",
        },
        onSubmit: async (values) => {
            setLoadingBtn(true);
            const newData = {
                ...values,
            };
            delete newData["confirmPass"];

            await axios
                .post(`${BASE_URL}/adminUser`, newData)
                .then((response) => {
                    showToast("success", "Success", response?.data?.message);
                    setLoadingBtn(false);
                    formik.resetForm();
                })
                .catch((err) => {
                    showToast("error", "Error", err.response.data.message);
                    setLoadingBtn(false);
                });
        },
    });

    useEffect(() => {
        const hasTruthyValue = Object.values(formik.values).some((value) => Boolean(value));

        setSearchBtnDisable(!hasTruthyValue);
    }, [formik.values]);

    useEffect(() => {
        dispatch(fetchRoleListAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <div className="card">
            <div className=" my-3">
                <div>
                    <p className="Page__Header">Add New User</p>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12 md:col-4">
                        <label htmlFor="firstName" className="Label__Text">
                            First Name
                        </label>
                        <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} placeholder="Enter First Name" className="Input__Round" />
                        {getFormErrorMessage("firstName")}
                    </div>
                    <div className="col-12 md:col-4">
                        <label htmlFor="lastName" className="Label__Text">
                            Last Name
                        </label>
                        <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} placeholder="Enter Last Name" className="Input__Round" />
                        {getFormErrorMessage("lastName")}
                    </div>

                    <div className="col-12 md:col-4">
                        <label htmlFor="email" className="Label__Text">
                            Email
                        </label>
                        <InputText id="email" value={formik.values.email} onChange={formik.handleChange} placeholder="Enter Email" className="Input__Round" />
                        {getFormErrorMessage("email")}
                    </div>
                    <div className="col-12 md:col-4">
                        <label htmlFor="password" className="Label__Text">
                            Password
                        </label>
                        <InputText id="password" value={formik.values.password} onChange={formik.handleChange} placeholder="Enter Password" className="Input__Round" />
                        {getFormErrorMessage("password")}
                    </div>
                    <div className="col-12 md:col-4">
                        <label htmlFor="confirmPass" className="Label__Text">
                            Confirm Password
                        </label>
                        <InputText id="confirmPass" value={formik.values.confirmPass} onChange={formik.handleChange} placeholder="Enter Password" className="Input__Round" />
                        {getFormErrorMessage("confirmPass")}
                    </div>
                    <div className="col-12 md:col-4">
                        <label htmlFor="whatsApp" className="Label__Text">
                            WhatsApp
                        </label>
                        <InputText id="whatsApp" value={formik.values.whatsApp} onChange={formik.handleChange} placeholder="Enter WhatsApp" className="Input__Round" />
                        {getFormErrorMessage("whatsApp")}
                    </div>
                    <div className="col-12 md:col-4">
                        <label htmlFor="role" className="Label__Text">
                            Role
                        </label>
                        <Dropdown id="role" options={roleData?.data} optionLabel="title" optionValue="_id" value={formik.values.role} onChange={formik.handleChange} placeholder="Select Role" className="Dropdown__Round" />
                        {getFormErrorMessage("role")}
                    </div>
                </div>
                <div className="flex justify-content-center mt-3">
                    <Button label="Add" type="submit" className="Btn__Dark" disabled={searchBtnDisable} loading={loadingBtn} />
                    <Button label="Back" type="button" className="Btn__Dark" onClick={handleClick} />
                </div>
            </form>
            <Toast ref={toast} />
        </div>
    );
};

export default AddUser;
