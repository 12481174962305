import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../../../../config";
import { Toast } from "primereact/toast";

const AddLevelTwoQuestion = () => {
    const [searchBtnDisable, setSearchBtnDisable] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { rowData } = location.state || {};
    const handleClick = () => {
        navigate("/managequestions");
    };
    const toast = useRef(null);

    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };
    const formik = useFormik({
        initialValues: {
            text: "",
            message: "",
        },
        onSubmit: async (values, { resetForm }) => {
            setLoadingBtn(true);
            const newData = {
                text: values?.text,
                message: values?.message,
                parent: rowData?._id,
                parentId: rowData?.id,
            };
            await axios
                .post(`${BASE_URL}/levelTwoQuestion`, newData)
                .then((response) => {
                    showToast("success", "Success", response?.data?.message);
                    setLoadingBtn(false);
                    resetForm();
                    setTimeout(() => {
                        navigate("/managequestions");
                    }, 1000);
                })
                .catch((err) => {
                    showToast("error", "Error", err.response.data.message);
                    setLoadingBtn(false);
                });
        },
    });

    useEffect(() => {
        const hasTruthyValue = Object.values(formik.values).some((value) => Boolean(value));

        setSearchBtnDisable(!hasTruthyValue);
    }, [formik.values]);

    return (
        <div className="card">
            <div className=" my-3">
                <div>
                    <p className="Page__Header">Add Level Two Question</p>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-4">
                        <label htmlFor="text" className="Label__Text">
                            Add Text
                        </label>
                        <InputText id="text" value={formik.values.text} onChange={formik.handleChange} placeholder="Enter L2 Question" className="Input__Round" />
                    </div>
                    <div className="col-4">
                        <label htmlFor="message" className="Label__Text">
                            Message
                        </label>
                        <InputText id="message" value={formik.values.message} onChange={formik.handleChange} placeholder="Enter Message" className="Input__Round" />
                    </div>
                </div>
                <div className="flex justify-content-center">
                    <Button label="Add" type="submit" className="Btn__Dark" disabled={searchBtnDisable} loading={loadingBtn} />
                    <Button label="Back" type="button" className="Btn__Dark" onClick={handleClick} />
                </div>
            </form>
            <Toast ref={toast} />
        </div>
    );
};

export default AddLevelTwoQuestion;
