import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";

import { Checkbox } from "primereact/checkbox";

import { useDispatch, useSelector } from "react-redux";
import { fetchRoleListAction } from "../../../../store/role/RoleAction";
import axios from "axios";
import BASE_URL from "../../../../../config";
import { Toast } from "primereact/toast";
import * as Yup from "yup";

const AddLevelOneQuestion = () => {
    const { roleData } = useSelector((state) => state.roleList);
    const [searchBtnDisable, setSearchBtnDisable] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useRef(null);

    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };

    const handleClick = () => {
        navigate("/managequestions");
    };

    const validationSchema = Yup.object({
        role: Yup.string().required("This Field is required"),
        text: Yup.string().required("This Field is required"),
        message: Yup.string().required("This Field is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            text: "",
            role: "",
            message: "",
            hasMenu: true,
        },
        onSubmit: async (values) => {
            setLoadingBtn(true);
            await axios
                .post(`${BASE_URL}/levelOneQuestion`, values)
                .then((response) => {
                    showToast("success", "Success", response?.data?.message);
                    setLoadingBtn(false);
                    formik.resetForm();
                    setTimeout(() => {
                        navigate("/managequestions");
                    }, 1000);
                })
                .catch((err) => {
                    showToast("error", "Error", err.response.data.message);
                    setLoadingBtn(false);
                });
        },
    });

    useEffect(() => {
        const hasTruthyValue = Object.values(formik.values).some((value) => {
            if (typeof value !== "boolean") return Boolean(value);
        });

        setSearchBtnDisable(!hasTruthyValue);
    }, [formik.values]);

    useEffect(() => {
        dispatch(fetchRoleListAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <div className="card">
            <div className=" my-3">
                <div>
                    <p className="Page__Header">Add Level One Question</p>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-4">
                        <label htmlFor="text" className="Label__Text">
                            Add Text
                        </label>
                        <InputText id="text" value={formik.values.text} onChange={formik.handleChange} placeholder="Enter Question" className="Input__Round" />
                        {getFormErrorMessage("text")}
                    </div>
                    <div className="col-4">
                        <label htmlFor="text" className="Label__Text">
                            Message
                        </label>
                        <InputText id="message" value={formik.values.message} onChange={formik.handleChange} placeholder="Enter First Message" className="Input__Round" />
                        {getFormErrorMessage("message")}
                    </div>

                    <div className="col-4">
                        <label htmlFor="role" className="Label__Text">
                            Role
                        </label>
                        <Dropdown id="role" options={roleData?.data} optionLabel="title" optionValue="_id" value={formik.values.role} onChange={formik.handleChange} placeholder="Select Role" className="Dropdown__Round" />
                        {getFormErrorMessage("role")}
                    </div>
                    <div className="col-4">
                        <label htmlFor="role" className="Label__Text">
                            Has Menu
                        </label>
                        <Checkbox
                            id="hasMenu"
                            optionLabel="title"
                            checked={formik.values.hasMenu}
                            onChange={() => {
                                formik.setFieldValue("hasMenu", !formik.values.hasMenu);
                            }}
                            className="checkBox"
                        />
                    </div>
                </div>
                <div className="flex justify-content-center">
                    <Button label="Add" type="submit" className="Btn__Dark" disabled={searchBtnDisable} loading={loadingBtn} />
                    <Button label="Back" type="button" className="Btn__Dark" onClick={handleClick} />
                </div>
            </form>
            <Toast ref={toast} />
        </div>
    );
};

export default AddLevelOneQuestion;
