import { Chart } from "primereact/chart";
import React, { useEffect, useState } from "react";

const StatsByCategory = ({ statsByCategoryData }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const countArr =
            statsByCategoryData &&
            statsByCategoryData.map((item) => {
                return item?.total;
            });
        const labelArr =
            statsByCategoryData &&
            statsByCategoryData.map((item) => {
                return item?._id;
            });

        const data = {
            labels: labelArr,
            datasets: [
                {
                    data: countArr,
                    backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
                    hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"],
                },
            ],
        };

        setChartData(data);
    }, [statsByCategoryData]);

    let horizontalOptions = {
        indexAxis: "x",
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                ticks: {
                    color: "#495057",
                },
                grid: {
                    color: "#ebedef",
                },
            },
            y: {
                ticks: {
                    color: "#495057",
                },
                grid: {
                    color: "#ebedef",
                },
            },
        },
    };
    return (
        <div className="col-12 md:col-6">
            <div className="">
                <div className="card">
                    <h5>
                        <p className="Page__Header">
                            <b>Stat By Category </b>
                        </p>
                    </h5>
                    <Chart type="bar" data={chartData} options={horizontalOptions} className="w-full" style={{ height: "35rem" }} />
                </div>
            </div>
        </div>
    );
};

export default StatsByCategory;
