import { createSlice } from "@reduxjs/toolkit";
import { fetchRoleListAction } from "./RoleAction";

const RoleListSlice = createSlice({
    name: "roleList",
    initialState: {
        roleData: {},
        roleLoading: false,
        roleError: null,
    },

    extraReducers: (builder) => {
        builder.addCase(fetchRoleListAction.pending, (state, action) => {
            state.roleLoading = true;
        });
        builder.addCase(fetchRoleListAction.fulfilled, (state, action) => {
            state.roleData = action.payload;
            state.roleLoading = false;
            state.roleError = null;
        });
        builder.addCase(fetchRoleListAction.rejected, (state, action) => {
            state.roleLoading = false;
            state.roleError = action.error.message;
        });
    },
});

export default RoleListSlice.reducer;
