import React, { useState, useEffect, useRef, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { AppTopbar } from "./AppTopbar";
// import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";
import { Route, Routes } from "react-router-dom";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
// import { menuNavigation } from "./navigation";
import NotFound from "./app/features/screens/NotFound/NotFound";
import Dashboard from "./app/features/screens/Dashboard/Dashboard";
import LoginScreen from "./app/features/screens/Auth/LoginScreen";
import { useSelector } from "react-redux";
import ManageUsers from "./app/features/screens/Users/ManageUsers";
import AddUser from "./app/features/screens/Users/AddUser";
import ManageMachines from "./app/features/screens/Machines/ManageMachines";
import AddMachine from "./app/features/screens/Machines/AddMachine";
import ManageComplains from "./app/features/screens/Complains/ManageComplains";
import ChatBox from "./app/features/screens/Complains/ChatBox";
import EditUser from "./app/features/screens/Users/EditUser";
import EditMachine from "./app/features/screens/Machines/EditMachine";
import ManageQuestions from "./app/features/screens/Questions/ManageQuestions";
import AddLevelOneQuestion from "./app/features/screens/Questions/LevelOne/AddLevelOneQuestion";
import AddLevelTwoQuestion from "./app/features/screens/Questions/LevelTwo/AddLevelTwoQuestion";
import LevelOneQuestionEdit from "./app/features/screens/Questions/LevelOne/LevelOneQuestionEdit";
import LevelTwoQuestionEdit from "./app/features/screens/Questions/LevelTwo/LevelTwoQuestionEdit";
import SendOtp from "./app/features/screens/Auth/SendOtp";
import { SocketContext } from "./Socket";
import { Toast } from "primereact/toast";
import ResetPassword from "./app/features/screens/Auth/ResetPassword";
import OpenComplaints from "./app/features/screens/Complains/OpenComplaints";
import CloseComplaints from "./app/features/screens/Complains/CloseComplaints";
import UpdateUserProfile from "./app/features/screens/UserProfile/UpdateUserProfile";
import SendAdminOtp from "./app/features/screens/UserProfile/SendAdminOtp";
import ResetAdminPassword from "./app/features/screens/UserProfile/ResetAdminPassword";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    // const navigate = useNavigate();
    const { loginData } = useSelector((state) => state.login);
    const menuNavigation = [
        {
            label: "",
            items:
                loginData?.adminUser?.role?.uid === "admin" || loginData?.adminUser?.role?.uid === "superAdmin"
                    ? [
                          {
                              label: <b>Dashboard</b>,
                              icon: "pi pi-fw pi-home",
                              to: "/",
                          },
                          {
                              label: <b>User Management</b>,
                              icon: "pi pi-users",
                              to: "/manageusers",
                          },
                          {
                              label: <b>Machine Management</b>,
                              icon: "pi pi-cog",
                              to: "/managemachines",
                          },
                          {
                              label: <b>Question Management</b>,
                              icon: "pi pi-question-circle",
                              to: "/managequestions",
                          },
                          {
                              label: <b>Complain Management</b>,
                              icon: "pi pi-comments",
                              //   to: "/managecomplains",
                              items: [
                                  { label: "Open Cmplaints", icon: "pi pi-unlock", to: "/opencomplains" },
                                  { label: "Close Complaints", icon: "pi pi-lock", to: "/closecomplains" },
                              ],
                          },
                      ]
                    : [
                          {
                              label: <b>Dashboard</b>,
                              icon: "pi pi-fw pi-home",
                              to: "/",
                          },
                          {
                              label: <b>Complain Management</b>,
                              icon: "pi pi-comments",
                              //   to: "/managecomplains",
                              items: [
                                  { label: "Open Cmplaints", icon: "pi pi-unlock", to: "/opencomplains" },
                                  { label: "Close Complaints", icon: "pi pi-lock", to: "/closecomplains" },
                              ],
                          },
                      ],
        },
    ];

    PrimeReact.ripple = true;
    let menuClick = false;
    let mobileTopbarMenuClick = false;
    const { socket } = useContext(SocketContext);
    const toast = useRef(null);
    if (window.localStorage.getItem("newLogin") === "1") {
        window.localStorage.setItem("newLogin", "0");
        window.location.reload();
    }

    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };
    useEffect(() => {
        setTimeout(() => {
            socket.on("newComplaint", (data) => {
                showToast("success", "New Complaint", "You have a new complaint");
            });
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    return (
        <>
            {!loginData ? (
                <>
                    <Routes>
                        <Route path="/sendotp" element={<SendOtp />} />
                        <Route path="/resetpassword" element={<ResetPassword />} />
                        <Route path="*" element={<LoginScreen />} />
                    </Routes>
                </>
            ) : (
                <div className={wrapperClass} onClick={onWrapperClick}>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                    <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                    <div className="layout-sidebar" onClick={onSidebarClick}>
                        <AppMenu model={menuNavigation} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                    </div>

                    <div className="layout-main-container">
                        <div className="layout-main">
                            <Routes>
                                {(loginData?.adminUser?.role?.uid === "admin" || loginData?.adminUser?.role?.uid === "superAdmin") && (
                                    <>
                                        <Route path="/manageusers" element={<ManageUsers />} />
                                        <Route path="/adduser" element={<AddUser />} />
                                        <Route path="/edituser" element={<EditUser />} />
                                        <Route path="/managemachines" element={<ManageMachines />} />
                                        <Route path="/addmachine" element={<AddMachine />} />
                                        <Route path="/editmachine" element={<EditMachine />} />
                                        <Route path="/managequestions" element={<ManageQuestions />} />

                                        <Route path="/addlevelonequestion" element={<AddLevelOneQuestion />} />
                                        <Route path="/addleveltwoquestion" element={<AddLevelTwoQuestion />} />
                                        <Route path="/levelonequestionedit" element={<LevelOneQuestionEdit />} />
                                        <Route path="/leveltwoquestionedit" element={<LevelTwoQuestionEdit />} />
                                    </>
                                )}
                                <Route path="*" element={<NotFound />} />
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/chat" element={<ChatBox />} />
                                {/* <Route path="/managecomplains" element={<ManageComplains />} /> */}
                                <Route path="/opencomplains" element={<OpenComplaints />} />
                                <Route path="/sendadminotp" element={<SendAdminOtp />} />
                                <Route path="/resetadminpassword" element={<ResetAdminPassword />} />
                                <Route path="/closecomplains" element={<CloseComplaints />} />
                                <Route path="/updateuserprofile" element={<UpdateUserProfile />} />
                            </Routes>
                        </div>

                        {/* <AppFooter layoutColorMode={layoutColorMode} /> */}
                    </div>

                    <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                    <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                        <div className="layout-mask p-component-overlay"></div>
                    </CSSTransition>
                </div>
            )}
            <Toast ref={toast} />
        </>
    );
};

export default App;
