import React from "react";
import { Button } from "primereact/button";
import { NavLink, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../../store/auth/AuthAction";

// import "./LoginScreen.css"; // Add your custom styles in a separate CSS file

export default function LoginScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const error = useSelector((state) => state.login);
    const errormsg = error?.loginError;
    const loading = error?.loginLoading;
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email").required("Email is required"),
            password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
        }),
        onSubmit: (values) => {
            dispatch(loginAction(values));
            navigate("/");
        },
    });

    return (
        <div className="login-container">
            <div className="login-card">
                <div className="login-header pb-5">
                    <p className="login-title">Login</p>
                </div>
                {errormsg && (
                    <div className="login-error">
                        <p className="error-text">Invalid Credentials</p>
                    </div>
                )}
                <div className="login-form">
                    <form onSubmit={formik.handleSubmit}>
                        <InputText type="text" name="email" className="p-inputtext-sm mb-4" placeholder="Enter email" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                        {formik.touched.email && formik.errors.email && <p className="error-text">{formik.errors.email}</p>}
                        <InputText type="password" name="password" className="p-inputtext-sm mb-2" placeholder="Enter password" value={formik.values.password} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                        {formik.touched.password && formik.errors.password && <p className="error-text">{formik.errors.password}</p>}
                        <Button type="submit" className="login-button" label="Login" disabled={loading} icon={loading ? "pi pi-spin pi-spinner" : ""} />
                        <div className="forgot-password">
                            <NavLink className="forgot-password-link" to={{ pathname: "/sendotp", state: { email: formik.values.email } }}>
                                Forgot Password?
                            </NavLink>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
